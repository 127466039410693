import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB9EO5y0bjNl1aYyRXfGlLdSgnId5jq5NM',
  authDomain: 'blazz-referral.firebaseapp.com',
  projectId: 'blazz-referral',
  storageBucket: 'blazz-referral.appspot.com',
  messagingSenderId: '729327843279',
  appId: '1:729327843279:web:361eb70e43330141c3c4ba',
  measurementId: 'G-5LHTK3DPRR',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { db, app }
