export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'merchants',
      displayName: 'menu.merchants',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'staffs',
      displayName: 'menu.staffs',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'categorys',
      displayName: 'menu.categorys',
      meta: {
        icon: 'library_books',
      },
    },
    {
      name: 'promotions',
      displayName: 'menu.promotions',
      meta: {
        icon: 'notifications',
      },
    },
    {
      name: 'orders',
      displayName: 'menu.orders',
      meta: {
        icon: 'bar_chart',
      },
    },
    {
      name: 'withdraws',
      displayName: 'menu.withdraws',
      meta: {
        icon: 'bar_chart',
      },
    },
  ] as INavigationRoute[],
}
